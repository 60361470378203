/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { NextPage } from "next";
import Head from "next/head";
import { useMemo, useState } from "react";

import { ContentfulPage } from "#components/contentful/contentful.types";
import { parseGenericPage } from "#components/pages/contentful/GenericPage/GenericPage";
import { meta } from "#constants/meta";
import { getGenericPage } from "#services/ContentfulService";
import useAnonymousExperimentStore from "#store/local.anonymousABTest";
import { Meta } from "#v2-components/organisms/Meta";

const style = {
  display: "flex",
  flexDirection: "column" as const,
  width: "100vw",
  background: "white",
};

const Home: NextPage<{ contentful: ContentfulPage }> = ({ contentful }) => {
  const [_hasDoneFirstLoad, setHasDoneFirstLoad] = useState(false);
  const { setReady: setExperimentsReady } = useAnonymousExperimentStore();
  const { header, sectionList } = useMemo(() => {
    const sectionListInitial = parseGenericPage(
      contentful.pageSections,
      setExperimentsReady,
      () => {
        setHasDoneFirstLoad(true);
      },
    );
    const headerInitial = sectionListInitial.shift();
    return { header: headerInitial, sectionList: [sectionListInitial] };
  }, [contentful]);

  const sections = (() => {
    return sectionList;
  })();

  const schemaOrgOrganizationStructuredData = [
    {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      name: "Tenet Energy",
      logo: "https://tenet.com/img/logo.svg",
      url: "https://tenet.com",
      description: contentful.metaTag,
    },
  ];
  const schemaOrgReviewStructuredData = [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: "Tenet EV Financing",
      description:
        "Fast, affordable financing from trusted EV experts. Lower monthly payments by more than $200 when you switch to Tenet. Get Funded in 48 hours.",
      review: {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: 5,
          bestRating: 5,
        },
        author: {
          "@type": "Person",
          name: "Joe Cartaya",
        },
        url: "https://www.google.com/maps/reviews/@40.7235269,-74.0008853,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURKdjRLMU9nEAE!2m1!1s0x0:0xef3165535fe47b3a?hl=en-US&entry=ttu",
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: 4.6,
        reviewCount: 21,
      },
    },
  ];

  return (
    <div style={style}>
      <Meta
        unique={meta.W3_DESCRIPTION}
        content={contentful.metaTag}
        name="description"
      />

      <Meta
        unique={meta.OG_TITLE}
        property="og:title"
        content={contentful.titleTag}
      />
      <Meta
        unique={meta.OG_DESCRIPTION}
        property="og:description"
        content={contentful.metaTag}
      />

      <Meta
        unique={meta.TC_TITLE}
        name="twitter:title"
        content={contentful.titleTag}
      />
      <Meta
        unique={meta.TC_DESCRIPTION}
        name="twitter:description"
        content={contentful.metaTag}
      />
      <Head>
        <script
          key={meta.SO_STRUCTURED_ORG_DATA}
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaOrgOrganizationStructuredData),
          }}
        />
        <script
          key={meta.SO_STRUCTURED_REVIEW_DATA}
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schemaOrgReviewStructuredData),
          }}
        />

        <title key={meta.W3_TITLE}>
          {contentful?.titleTag || "Tenet Energy"}
        </title>
      </Head>
      {header}
      {sections}
    </div>
  );
};

export const getStaticProps = async () => {
  const contentful = await getGenericPage("/");
  return { props: { contentful } };
};

export default Home;
